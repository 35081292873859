<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap37Context"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 37: Context" image-name="context.jpg" image-alt="Context"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>What is context?</p></li>
<li><p>What is a linked list?</p></li>
<li><p>How to use the context package.</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Context derivation</p></li>
<li><p>Linked list</p></li>
<li><p>Context key-value pair</p></li>
<li><p>Cancellation</p></li>
<li><p>Timeout</p></li>
<li><p>Deadline</p></li>
</ul>
<div id="introduction" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> Introduction <a href="#introduction"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>This chapter is dedicated to the context package. In the first part of this chapter, we will discover what is a “context” and what its purposes are. In the second part, we will see how we can use the context package in real-life programs.</p>
<div id="what-is-context-oriented-programming" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> What is Context Oriented Programming? <a href="#what-is-context-oriented-programming"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="definition" class="anchor"></div>
<h2 data-number="4.1"><span class="header-section-number">4.1</span> Definition <a href="#definition"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Context comes from the Latin word “contexo”. It means to unite, connect, link something with a group of other things. We have here the idea that the context of something is a set of links and connections with other things. In the day to day language, we use expressions like :</p>
<ul>
<li><p>Take something out of context.</p></li>
<li><p>In the context of something.</p></li>
</ul>
<p>Things, actions, words have a context, have links with other things. And if we take something out of its context, we reduce it to something that we can misunderstand. Context is an aggregation of information that improves decisions. What is part of context? Here is a partial list :</p>
<ul>
<li><p>Location</p></li>
<li><p>Date</p></li>
<li><p>History</p></li>
<li><p>People</p></li>
</ul>
<p>To better understand why context is important to us, let’s take some examples :</p>
<div id="context-increase-the-understanding-of-an-event" class="anchor"></div>
<h2 data-number="4.2"><span class="header-section-number">4.2</span> Context increase the understanding of an event <a href="#context-increase-the-understanding-of-an-event"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Imagine that during a walk, you hear a conversation between two people :</p>
<dl>
<dt>Alice</dt>
<dd><p>Did you see the match last week?</p>
</dd>
<dt>Bob</dt>
<dd><p>Yes!</p>
</dd>
<dt>Alice</dt>
<dd><p>After this, I’m sure they will win the next one!</p>
</dd>
<dt>Bob</dt>
<dd><p>Sure, I will bet one thousand on that</p>
</dd>
</dl>
<p>They speak about a “match”. A team has won a match last week and has a lot of chance to win another match next week. We have no idea about which team and which kind of sport it is.</p>
<p>The context of the conversation can help us to understand it. If the conversation is happening in New York, we can guess that it has to do with baseball or basketball because those sports are very popular there. If this conversation happens in Paris, the chance that they are speaking about football is very high.</p>
<p>What we are doing here is that we add context to understand something. Here we spoke about the place. We can also add the time factor in the context of the conversation. If we know when it happened, we will be able to browse through the week’s sports results to understand better.</p>
<div id="context-change-behaviors" class="anchor"></div>
<h2 data-number="4.3"><span class="header-section-number">4.3</span> Context change behaviors <a href="#context-change-behaviors"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The analysis of the context of an event will change actors’ behavior. Try to answer some of those questions :</p>
<ul>
<li><p>Do you have better manners when you are in your home country and in another country?</p></li>
<li><p>Do you use the same language level at the office and with your family?</p></li>
<li><p>Do you dress like every day when you go to a job interview?</p></li>
</ul>
<p>The response is probably “No” to those three questions. That’s because of context. We are acting differently depending on the context. Context is affecting our behaviors. The environment has an impact on our actions and reactions.</p>
<div id="context-in-computer-science" class="anchor"></div>
<h2 data-number="4.4"><span class="header-section-number">4.4</span> Context in computer science <a href="#context-in-computer-science"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Usually, we design computer programs to execute a predefined task. The specified routines that we have implemented are always executing the same way. The program is not changing depending on the user that is using it. Its behavior is not changing when its environment change.</p>
<p>The idea of context-oriented programming is to introduce variations in programs that are influenced by context. Abowd gives an interesting definition of context in 1999 : “Context is any information that we can use to characterize the situation of an entity. An entity is a person, place, or object that is considered relevant to the interaction between a user and an application, including the user and applications themselves.”<b-link class="citation" data-cites="abowd1999towards" href="#abowd1999towards" >[@abowd1999towards]</b-link>.</p>
<p>Implicit and explicit information are the building blocks of the context. Programmers should consider context to build applications that can adapt their behavior at runtime.</p>
<p>What does it mean to be intelligent? The word “intelligence” comes from the Latin root “intellego” which means to discern, to unravel, to notice, to realize. Something is intelligent if it can discern and understand. Introducing context into applications does not make them intelligent, but they tend to make them aware of their environment and their users.</p>
<div id="the-context-package-history-and-use-cases" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> The “context” package: history and use cases <a href="#the-context-package-history-and-use-cases"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="package-history" class="anchor"></div>
<h2 data-number="5.1"><span class="header-section-number">5.1</span> Package history <a href="#package-history"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>This package was first developed internally by Google developers. It has been introduced in the standard library of Go. Before that, it was available in Go sub repositories<a href="#fn1" class="footnote-ref" id="fnref1" role="doc-noteref"><sup>1</sup></a>.</p>
<div id="usages" class="anchor"></div>
<h2 data-number="5.2"><span class="header-section-number">5.2</span> Usages <a href="#usages"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The context package has two main usages :</p>
<div id="cancellation-propagation" class="anchor"></div>
<h4 data-number="5.2.0.1"><span class="header-section-number">5.2.0.1</span> Cancellation propagation <a href="#cancellation-propagation"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>To understand this usage, let’s take the example of an imaginary construction company named FooBar.</p>
<p>Paris’s city has missioned this company to build a gigantic pool. The mayor of Paris has defended its idea among representants of the population, and the project has been approved. The company begins to work on the project; the project manager has ordered all the raw materials needed to build the pool. Four months have passed, but the mayor has changed, and the project has been canceled!</p>
<p>The project manager from FooBar is mad; the company has to cancel 156 orders. He starts to join them by phone one by one. Some of them have also ordered raw materials from other construction companies. Everybody is suffering from this rapid situation evolution.</p>
<p>Now let’s imagine that the project manager does not cancel subcontractors’ orders. The other companies will produce the desired goods, but they will not be paid. This is a big waste of resources.</p>
<p>As you can visualize in figure <a href="#fig:Cancellation-propagation" data-reference-type="ref" data-reference="fig:Cancellation-propagation">1</a> cancellation of the project is propagating to all the workers that were involved indirectly. The city Council cancels the project; the FooBar company is also canceling the orders to contractors.</p>
<p>In construction and other human activities, we always have a way to cancel work. We can introduce a cancellation policy into our programs with the context package. When a request is made to a web server, we can cancel all the work chain if the client has dropped the connection!</p>
<figure>
<b-img :src="require('@/assets/images/cancellation_propagation.png')" alt="Cancellation propagation[fig:Cancellation-propagation]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Cancellation propagation<span id="fig:Cancellation-propagation" label="fig:Cancellation-propagation">[fig:Cancellation-propagation]</span></figcaption>
</figure>
<div id="transmission-of-request-scoped-data-along-with-the-call-stack" class="anchor"></div>
<h4 data-number="5.2.0.2"><span class="header-section-number">5.2.0.2</span> Transmission of request-scoped data along with the call stack <a href="#transmission-of-request-scoped-data-along-with-the-call-stack"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>When a request is made to a web server, the web server function responsible for its treatment will not do the job alone. The request will pass through a chain of functions and methods, and then the response will be sent. A single request can generate new requests to other microservices in a microservice architecture! This chain of a function call is the “call stack”. We will see in this section why it can be useful to transmit data along with the call stack.</p>
<p>We will take another example: the development of a web server for a shopping application. We have a user that interacts with our application.</p>
<ul>
<li><p>The user will go to the login page with its web browser</p></li>
<li><p>Fill it’s login details</p></li>
<li><p>The web browser will send an authentication request to the server that will forward the request to the Authentication Service</p></li>
<li><p>The server will build the “My Account” page (via a template, for instance) and send the user’s response.</p></li>
<li><p>If the user requests the “last orders” page then, the server will need to call the Order Service to retrieve them.</p></li>
</ul>
<figure>
<b-img :src="require('@/assets/images/request_context.png')" alt="An application sequence"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">An application sequence</figcaption>
</figure>
<p>Which data can we add to our context?</p>
<ul>
<li><p>We can keep into context the <strong>type of device that sent the request</strong>.</p>
<ul>
<li><p>If the device is a cellphone, we can choose to load a lightweight template to improve the user experience.</p></li>
<li><p>The order service can also load only the last five orders to reduce the page’s rendering time.</p></li>
</ul></li>
<li><p>We can keep into the context the id of the authenticated user.</p></li>
<li><p>We can also keep the IP of the incoming request.</p>
<ul>
<li>The authentication layer can use it to block suspicious activity (introduction of a blocklist, detection of false, multiple login attempts)</li>
</ul></li>
<li><p>Another very common use case is to generate a single request id. The requestId is passed to each layer of the application. With the id, the team that will cope with maintenance will have the ability to trace in logs the request.</p></li>
</ul>
<div id="set-deadlines-and-timeouts" class="anchor"></div>
<h4 data-number="5.2.0.3"><span class="header-section-number">5.2.0.3</span> Set deadlines and timeouts <a href="#set-deadlines-and-timeouts"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>A deadline is a time at which a task should be finished. A timeout is a very similar notion. Instead of considering a precise date and time in the calendar, we consider a max allowed duration. We can use a context to define a time limit for long-running processes. Here is an example:</p>
<ul>
<li><p>You develop a server, and your client has a specified timeout of 1 second.</p></li>
<li><p>You can set a context with a timeout of 1 second; after this duration, you know that the client will drop the connection.</p></li>
<li><p>In this case, again, we want to avoid wasting resources.</p></li>
</ul>
<div id="the-context-interface" class="anchor"></div>
<h2 data-number="5.3"><span class="header-section-number">5.3</span> The Context interface <a href="#the-context-interface"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The context package exposes an interface that is composed of four methods :</p>
<pre v-highlightjs><code class="go" v-pre >type Context interface {
    Deadline() (deadline time.Time, ok bool)
    Done() &lt;-chan struct{}
    Err() error
    Value(key interface{}) interface{}
}</code></pre>
<p>In the next section, we will see how to use the package</p>
<div id="linked-list" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> Linked list <a href="#linked-list"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The context package is built with a standard data structure: the linked list. To fully understand how context works, we first need to understand linked lists.</p>
<p>A linked list is a collection of data elements. The type of data stored in the list is not restricted; it can be integers, strings, structs, floats ...etc. Each element of the list is a node. Each node contains two things :</p>
<ul>
<li><p>The data value</p></li>
<li><p>The address in memory of the next element in the list. In other words, this is a pointer to the next value.</p></li>
</ul>
<p>You can see a visual representation of a linked list in the figure <a href="#fig:Linkedl-pointers-and-values" data-reference-type="ref" data-reference="fig:Linkedl-pointers-and-values">3</a>.</p>
<p>The list is “linked”, nodes in the list have a child (the next element in the list) and a parent (the last element in the list). Note that this remark is not true; the first node in the list has no parent. It’s the root, the origin, the head of the list. There is another notable exception the final node does not have any child.</p>
<figure>
<b-img :src="require('@/assets/images/linked_list.png')" alt="Linked List[fig:Linked-List]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Linked List<span id="fig:Linked-List" label="fig:Linked-List">[fig:Linked-List]</span></figcaption>
</figure>
<figure>
<b-img :src="require('@/assets/images/linked_list_pointers.png')" alt="Linked List : pointers and values[fig:Linkedl-pointers-and-values]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Linked List : pointers and values<span id="fig:Linkedl-pointers-and-values" label="fig:Linkedl-pointers-and-values">[fig:Linkedl-pointers-and-values]</span></figcaption>
</figure>
<div id="the-root-context-background" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> The root context: Background <a href="#the-root-context-background"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In most programs, we create a Background concept at our program’s root. For instance, in the main function that will launch our application. To create the root context, you can use the following syntax :</p>
<pre v-highlightjs><code class="go" v-pre >ctx := context.Background()</code></pre>
<p>The call to the <span v-highlightjs><code class="go" v-pre style="display: inline">Background()</code></span> function will return a pointer to an empty context. Internally the call to <span v-highlightjs><code class="go" v-pre style="display: inline">Background()</code></span> will create a new <span v-highlightjs><code class="go" v-pre style="display: inline">context.emptyCtx</code></span>.</p>
<p>This type is not exposed :</p>
<pre v-highlightjs><code class="go" v-pre >type emptyCtx int</code></pre>
<p>The underlying type of <span v-highlightjs><code class="go" v-pre style="display: inline">emptyCtx</code></span> is <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span>. This type implements the four methods that are required by the Context interface:</p>
<pre v-highlightjs><code class="go" v-pre >func (*emptyCtx) Deadline() (deadline time.Time, ok bool) {
    return
}

func (*emptyCtx) Done() &lt;-chan struct{} {
    return nil
}

func (*emptyCtx) Err() error {
    return nil
}

func (*emptyCtx) Value(key interface{}) interface{} {
    return nil
}</code></pre>
<p>Note that the <span v-highlightjs><code class="go" v-pre style="display: inline">emptyCtx</code></span> type also implements the interface <span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Stringer</code></span>. This allows us to do a <span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Println(ctx)</code></span>:</p>
<pre v-highlightjs><code class="go" v-pre >fmt.Println(reflect.TypeOf(ctx))
// *context.emptyCtx
fmt.Println(ctx)
// context.Background</code></pre>
<div id="add-context-to-your-functionmethods" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> Add context to your function/methods <a href="#add-context-to-your-functionmethods"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>When your root context is created, we can pass it to functions or methods.</p>
<p>But before that, we have to add to our function a context parameter :</p>
<pre v-highlightjs><code class="go" v-pre >func foo1(ctx context.Context, a int) {
    //...
}</code></pre>
<p>In the previous listing, you note two Go idioms that are widely used inside go projects :</p>
<ol type="1">
<li><p>The context is the first argument of a function</p></li>
<li><p>The context argument is named <span v-highlightjs><code class="go" v-pre style="display: inline">ctx</code></span></p></li>
</ol>
<div id="deriving-context" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> Deriving context <a href="#deriving-context"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>We have created our root context in the previous section. This context is empty; it does nothing. What we can do is derive another child context from our empty context :</p>
<figure>
<b-img :src="require('@/assets/images/deriving_context.png')" alt="Deriving Contexts[fig:Deriving-Contexts]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Deriving Contexts<span id="fig:Deriving-Contexts" label="fig:Deriving-Contexts">[fig:Deriving-Contexts]</span></figcaption>
</figure>
<p>To derive a context, you can use the following functions :</p>
<ul>
<li><p>WithCancel</p></li>
<li><p>WithTimeout</p></li>
<li><p>WithDeadline</p></li>
<li><p>WithValue</p></li>
</ul>
<div id="withcancel" class="anchor"></div>
<h1 data-number="10"><span class="header-section-number">10</span> WithCancel <a href="#withcancel"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The function <span v-highlightjs><code class="go" v-pre style="display: inline">WithCancel</code></span> takes only one argument named <span v-highlightjs><code class="go" v-pre style="display: inline">parent</code></span>. This argument represents the context that we want to derive. We will create a new context, and the parent context will keep a reference to this new child context.</p>
<p>Let’s take a look at the signature of the WithCancel function :</p>
<pre v-highlightjs><code class="go" v-pre >func WithCancel(parent Context) (ctx Context, cancel CancelFunc)</code></pre>
<p>This function returns the next child context and a CancelFunc. CancelFunc is a custom type of the context package :</p>
<pre v-highlightjs><code class="go" v-pre >type CancelFunc func()</code></pre>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">CancelFunc</code></span> is a named type, its underlying type is <span v-highlightjs><code class="go" v-pre style="display: inline">func()</code></span>. This function “tells an operation to abandon its work” (Golang sources). Calling <span v-highlightjs><code class="go" v-pre style="display: inline">WithCancel</code></span> will give us a way to cancel an operation. Here is how to create a derived context :</p>
<pre v-highlightjs><code class="go" v-pre >ctx, cancel := context.WithCancel(context.Background())</code></pre>
<p>To cancel the operation, you need to call cancel :</p>
<pre v-highlightjs><code class="go" v-pre >cancel()</code></pre>
<div id="withtimeout-withdeadline" class="anchor"></div>
<h1 data-number="11"><span class="header-section-number">11</span> WithTimeout / WithDeadline <a href="#withtimeout-withdeadline"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li>A timeout is the maximum amount of time attributed to a process to finish normally. For any process that takes a variable amount of time to execute, we can add a timeout, i.e., a fixed amount of time allowed to wait. Without timeouts, our application can wait indefinitely for a process to finish.</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >ctx, cancel := context.WithTimeout(context.Background(), 3*time.Second)</code></pre>
<ul>
<li>A deadline is a specified point in time. When you set a deadline, you specify that a process will not exceed it.</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >deadline := time.Date(2021, 12, 12, 3, 30, 30, 30, time.UTC)
ctx, cancel := context.WithDeadline(context.Background(), deadline)</code></pre>
<div id="example-usage" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="12"><span class="header-section-number">12</span> Example usage <a href="#example-usage"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="without-context" class="anchor"></div>
<h2 data-number="12.1"><span class="header-section-number">12.1</span> Without context <a href="#without-context"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Let’s take an example: we will design an application that has to make an HTTP request to a web server to get data and then display it to its user. We will first consider the application without context then we will add context to it.</p>
<div id="client" class="anchor"></div>
<h3 data-number="12.1.1"><span class="header-section-number">12.1.1</span> Client <a href="#client"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<pre v-highlightjs><code class="go" v-pre >package main

import (
    &quot;log&quot;
    &quot;net/http&quot;
)

func main() {
    req, err := http.NewRequest(&quot;GET&quot;, &quot;http://127.0.0.1:8989&quot;, nil)
    if err != nil {
        panic(err)
    }
    resp, err := http.DefaultClient.Do(req)
    if err != nil {
        panic(err)
    }
    log.Println(&quot;resp received&quot;, resp)
}</code></pre>
<p>We have here a simple http client. We create a GET request that will call <span v-highlightjs><code class="go" v-pre style="display: inline">"http://127.0.0.1:8989"</code></span>. If we cannot create the request, we make our program panic. Then we use the default HTTP client (<span v-highlightjs><code class="go" v-pre style="display: inline">http.DefaultClient</code></span>) to send the request to the server (with the method <span v-highlightjs><code class="go" v-pre style="display: inline">Do</code></span>).</p>
<p>The response received is then printed to the user.</p>
<div id="server" class="anchor"></div>
<h3 data-number="12.1.2"><span class="header-section-number">12.1.2</span> Server <a href="#server"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>We have set up our client. We now have to set up our fake server.</p>
<pre v-highlightjs><code class="go" v-pre >package main

import (
    &quot;fmt&quot;
    &quot;log&quot;
    &quot;net/http&quot;
    &quot;time&quot;
)

func main() {
    http.HandleFunc(&quot;/&quot;, func(w http.ResponseWriter, r *http.Request) {
        log.Println(&quot;request received&quot;)
        time.Sleep(time.Second * 3)
        fmt.Fprintf(w, &quot;Response&quot;) // send data to client side
        log.Println(&quot;response sent&quot;)

    })
    err := http.ListenAndServe(&quot;127.0.0.1:8989&quot;, nil) // set listen port
    if err != nil {
        panic(err)
    }
}</code></pre>
<p>The code is simple. We first begin by setting our http handler with the function.<span v-highlightjs><code class="go" v-pre style="display: inline">http.HandleFunc</code></span>. This function takes two parameters, the path and the function that will respond to requests.</p>
<p>We wait 3 seconds with the instruction <span v-highlightjs><code class="go" v-pre style="display: inline">time.Sleep(time.Second * 3)</code></span> then we write the response. This sleep is here to fake the time needed for the server to answer. In this case, the response is simply <span v-highlightjs><code class="go" v-pre style="display: inline">"Response"</code></span>.</p>
<p>Then we launch our server that will listen to <span v-highlightjs><code class="go" v-pre style="display: inline">127.0.0.1:8989</code></span> (localhost, port 8989).</p>
<div id="first-test" class="anchor"></div>
<h3 data-number="12.1.3"><span class="header-section-number">12.1.3</span> First test <a href="#first-test"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>First, we launch the server; then, we launch the client. After 3 seconds, the client has received the response.</p>
<pre v-highlightjs><code class="go" v-pre >$ go run server.go
2019/04/22 12:17:11 request received
2019/04/22 12:17:14 response sent

$ go run client.go
2019/04/22 12:17:14 resp received &amp;{200 OK 200 HTTP/1.1 1 1 map[Content-Length:[8] Content-Type:[text/plain; charset=utf-8] Date:[Mon, 22 Apr 2019 10:17:14 GMT]] 0xc000132180 8 [] false false map[] 0xc00011c000 &lt;nil&gt;}</code></pre>
<p>As you can see, our client has to deal with 3 seconds latency. Let’s increase this in the server’s cod; let’s say that we now sleep for 1 minute. Our client will wait for 1 minute; it will block our application for 1 minute.</p>
<p>We can note here that our client application is condemned to wait for the server even if it takes an infinite amount of time. This is not a very good design. The user will not be happy to wait indefinitely for the application to answer. In my opinion, it is better to say to the user that something wrong happened than to let him wait indefinitely.</p>
<div id="context-on-the-client-side" class="anchor"></div>
<h2 data-number="12.2"><span class="header-section-number">12.2</span> Context on the client-side <a href="#context-on-the-client-side"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>We will keep the base of the code that we previously created. We will start by creating a root context :</p>
<pre v-highlightjs><code class="go" v-pre >rootCtx := context.Background()</code></pre>
<p>Then we will derive this context into a new one called ctx :</p>
<pre v-highlightjs><code class="go" v-pre >ctx, cancel := context.WithTimeout(rootCtx, 50*time.Millisecond)</code></pre>
<ul>
<li><p>The function <span v-highlightjs><code class="go" v-pre style="display: inline">WithTimeout</code></span> takes two arguments a context and a <span v-highlightjs><code class="go" v-pre style="display: inline">time.Duration</code></span>.</p></li>
<li><p>The second argument is the timeout duration.</p></li>
<li><p>Here we have set it to 50 milliseconds.</p></li>
<li><p>I suggest you create a config variable in a real-world application to hold the timeout duration. By doing so, you avoid the need to recompile your program to change the timeout.</p></li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">context.WithTimeout</code></span> will return:</p>
<ol type="1">
<li><p>the derived context</p></li>
<li><p>a cancel function</p></li>
</ol>
<p>The cancel function can be called to warn a sub-process that it should abandon what it is doing. Calling cancel will free resources that are associated with the context. To be sure that the cancel function will be called at the end of our program, we will use a defer statement :</p>
<pre v-highlightjs><code class="go" v-pre >defer cancel()</code></pre>
<p>The next step consists of creating the request and attach to it our brand new context :</p>
<pre v-highlightjs><code class="go" v-pre >req, err := http.NewRequest(&quot;GET&quot;, &quot;http://127.0.0.1:8989&quot;, nil)
if err != nil {
    panic(err)
}
// add context to our request
req = req.WithContext(ctx)</code></pre>
<p>The other lines are the same as the version without context.</p>
<p>Here is the complete client code :</p>
<pre v-highlightjs><code class="go" v-pre >// context/client-side/main.go
package main

import (
    &quot;context&quot;
    &quot;fmt&quot;
    &quot;net/http&quot;
    &quot;time&quot;
)

func main() {
    rootCtx := context.Background()
    req, err := http.NewRequest(&quot;GET&quot;, &quot;http://127.0.0.1:8989&quot;, nil)
    if err != nil {
        panic(err)
    }
    // create context
    ctx, cancel := context.WithTimeout(rootCtx, 50*time.Millisecond)
    defer cancel()
    // attach context to our request
    req = req.WithContext(ctx)
    resp, err := http.DefaultClient.Do(req)
    if err != nil {
        panic(err)
    }
    fmt.Println(&quot;resp received&quot;, resp)
}</code></pre>
<p>Now let’s test our new client. Here are the logs of the server :</p>
<pre v-highlightjs><code class="go" v-pre >2019/04/24 00:52:08 request received
2019/04/24 00:52:11 response sent</code></pre>
<p>We see that we have received a request and sent a response 3 seconds later. Here are the logs of our client :</p>
<pre v-highlightjs><code class="go" v-pre >panic: Get http://127.0.0.1:8989: context deadline exceeded</code></pre>
<p>We see that that <span v-highlightjs><code class="go" v-pre style="display: inline">http.DefaultClient.Do</code></span> has returned an <span v-highlightjs><code class="go" v-pre style="display: inline">error</code></span>.</p>
<ul>
<li><p>The text says that the deadline is exceeded.</p></li>
<li><p>Our request has been canceled because our server took 3 seconds to do its job. Even if the client has canceled the request, the server continued to do the job. We have to find a way to share that context between the client and the server.</p></li>
</ul>
<div id="context-on-the-server-side" class="anchor"></div>
<h2 data-number="12.3"><span class="header-section-number">12.3</span> Context on the server-side <a href="#context-on-the-server-side"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<div id="headers" class="anchor"></div>
<h3 data-number="12.3.1"><span class="header-section-number">12.3.1</span> Headers <a href="#headers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>An HTTP request comprises a set of Headers, a body, and a query string. When we send the request, Go will not transmit any information about the request context.</p>
<p>If you want to visualize the headers of the request, you can add the following lines in the server code :</p>
<pre v-highlightjs><code class="go" v-pre >fmt.Println(&quot;headers :&quot;)
for name, headers := range r.Header {
    for _, h := range headers {
        fmt.Printf(&quot;%s: %s\n&quot;, name, h)
    }
}</code></pre>
<p>We iterate through the request’s headers with a loop and print them. Here are the headers that are transmitted with our client :</p>
<pre v-highlightjs><code class="go" v-pre >headers :
User-Agent: Go-http-client/1.1
Accept-Encoding: gzip</code></pre>
<p>We have just two headers. The first one gives more information about the client used. The second informs the server that the client can accept gzipped data. Nothing about an eventual timeout.</p>
<p>But if we take a look at the <span v-highlightjs><code class="go" v-pre style="display: inline">http.Request</code></span>object, we can note that there is a method named <span v-highlightjs><code class="go" v-pre style="display: inline">Context()</code></span>. This method will retrieve the context of the request. If it has not been defined, it will return an empty context :</p>
<pre v-highlightjs><code class="go" v-pre >func (r *Request) Context() context.Context {
    if r.ctx != nil {
        return r.ctx
    }
    return context.Background()
}</code></pre>
<p>The documentation says that “the context is canceled when the client’s connection closes”. This means that inside the go server implementation, when the client connection is closed, the cancel function is called.</p>
<p>It means that inside our server, we have to listen to the channel returned by ctx.Done(). When we receive a message on that channel, we have to stop what we are currently doing.</p>
<div id="the-dowork-function" class="anchor"></div>
<h3 data-number="12.3.2"><span class="header-section-number">12.3.2</span> The doWork function <a href="#the-dowork-function"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Let’s see how to introduce that into our server.</p>
<p>For the example, we will introduce a new function <span v-highlightjs><code class="go" v-pre style="display: inline">doWork</code></span>. It will represent a compute-intensive task handled by our server. This <span v-highlightjs><code class="go" v-pre style="display: inline">doWork</code></span> is a placeholder for a CPU-intensive operation.</p>
<figure>
<b-img :src="require('@/assets/images/serverWithTimeout.png')" alt="Http server handler with context activity diagram"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Http server handler with context activity diagram</figcaption>
</figure>
<p>We will launch the <span v-highlightjs><code class="go" v-pre style="display: inline">doWork</code></span> function in a separate goroutine. This function will take as parameter the context and a channel where it will write its result. Let’s take a look at the code of this function :</p>
<pre v-highlightjs><code class="go" v-pre >// context/server-side/main.go 
//...

func doWork(ctx context.Context, resChan chan int) {
    log.Println(&quot;[doWork] launch the doWork&quot;)
    sum := 0
    for {
        log.Println(&quot;[doWork] one iteration&quot;)
        time.Sleep(time.Millisecond)
        select {
        case &lt;-ctx.Done():
            log.Println(&quot;[doWork] ctx Done is received inside doWork&quot;)
            return
        default:
            sum++
            if sum &gt; 1000 {
                log.Println(&quot;[doWork] sum has reached 1000&quot;)
                resChan &lt;- sum
                return
            }
        }
    }

}</code></pre>
<p>In the figure <a href="#fig:doWork-fct" data-reference-type="ref" data-reference="fig:doWork-fct">5</a> you can see the activity diagram for the doWork function.</p>
<p>In this function, we will use a channel to communicate with the caller. We create a for loop, and inside that loop, we will put a select statement. In this select statement, we have two cases :</p>
<ul>
<li><p>The channel returned by <span v-highlightjs><code class="go" v-pre style="display: inline">ctx.Done()</code></span> has been closed. It means that we receive the order to finish our work</p>
<ul>
<li>In this case, we will interrupt the loop, log a message and return.</li>
</ul></li>
<li><p>The default case (executed if any previous case are not executed)</p>
<ul>
<li><p>In this default case, we will increment the sum.</p></li>
<li><p>If the variable sum is becoming greater strictly than 1.000, we will send the result on the result channel (<span v-highlightjs><code class="go" v-pre style="display: inline">resChan</code></span>)</p></li>
</ul></li>
</ul>
<figure>
<b-img :src="require('@/assets/images/doWorkFunction.png')" alt="Activity diagram of the doWork function[fig:doWork-fct]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Activity diagram of the doWork function<span id="fig:doWork-fct" label="fig:doWork-fct">[fig:doWork-fct]</span></figcaption>
</figure>
<div id="the-server-handler" class="anchor"></div>
<h3 data-number="12.3.3"><span class="header-section-number">12.3.3</span> The server handler <a href="#the-server-handler"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Let’s see how we will use the <span v-highlightjs><code class="go" v-pre style="display: inline">doWork</code></span> function inside our server handler :</p>
<pre v-highlightjs><code class="go" v-pre >// context/server-side/main.go 
//...

func main() {
    http.HandleFunc(&quot;/&quot;, func(w http.ResponseWriter, r *http.Request) {
        log.Println(&quot;[Handler] request received&quot;)
        // retrieve the context of the request
        rCtx := r.Context()
        // create the result channel
        resChan := make(chan int)
        // launch the function doWork in a goroutine
        go doWork(rCtx, resChan)
        // Wait for
        // 1. the client drops the connection.
        // 2. the function doWork to finish it works
        select {
        case &lt;-rCtx.Done():
            log.Println(&quot;[Handler] context canceled in main handler, client has diconnected&quot;)
            return
        case result := &lt;-resChan:
            log.Println(&quot;[Handler] Received 1000&quot;)
            log.Println(&quot;[Handler] Send response&quot;)
            fmt.Fprintf(w, &quot;Response %d&quot;, result) // send data to client side
            return
        }
    })
    err := http.ListenAndServe(&quot;127.0.0.1:8989&quot;, nil) // set listen port
    if err != nil {
        panic(err)
    }
}</code></pre>
<p>We have changed the code of the handler to use the request context. The first thing to do here is to retrieve the context of the request :</p>
<pre v-highlightjs><code class="go" v-pre >rCtx := r.Context()</code></pre>
<p>Then we set up a channel of integers (<span v-highlightjs><code class="go" v-pre style="display: inline">resChan</code></span>) that will allow you to communicate with the <span v-highlightjs><code class="go" v-pre style="display: inline">doWork</code></span> function. We will launch the <span v-highlightjs><code class="go" v-pre style="display: inline">doWork</code></span> function in a separate goroutine.</p>
<pre v-highlightjs><code class="go" v-pre >resChan := make(chan int)
// launch the function doWork in a goroutine
go doWork(rCtx, resChan)</code></pre>
<p>Then we will use a select statement to wait for two possible events :</p>
<ol type="1">
<li><p>The client closes the connection; consequently, the cancel channel will be closed.</p></li>
<li><p>The function <span v-highlightjs><code class="go" v-pre style="display: inline">doWork</code></span> has finished its job. (We receive an integer from the <span v-highlightjs><code class="go" v-pre style="display: inline">resChan</code></span> channel)</p></li>
</ol>
<p>In option 1, we log a message, and then we return. When option 2 occurs, we use the result from the <span v-highlightjs><code class="go" v-pre style="display: inline">resChan</code></span> channel, and we write it to the response writer. Our client will receive the result of the doWork function computation.</p>
<p>Let’s run our server and our client. On figure <a href="#fig:Execution-logs-client-server-context" data-reference-type="ref" data-reference="fig:Execution-logs-client-server-context">6</a> you can see the execution logs of the client and the server program.</p>
<p>You can see that the handler receives a request, then launch the <span v-highlightjs><code class="go" v-pre style="display: inline">doWork</code></span> function. Then the handler receives the cancellation signal. This signal is then propagated to the <span v-highlightjs><code class="go" v-pre style="display: inline">doWork</code></span> function.</p>
<figure>
<b-img :src="require('@/assets/images/server_client_timeout.png')" alt="Execution logs for the client and the server[fig:Execution-logs-client-server-context]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Execution logs for the client and the server<span id="fig:Execution-logs-client-server-context" label="fig:Execution-logs-client-server-context">[fig:Execution-logs-client-server-context]</span></figcaption>
</figure>
<div id="withdeadline" class="anchor"></div>
<h1 data-number="13"><span class="header-section-number">13</span> WithDeadline <a href="#withdeadline"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="definition-1" class="anchor"></div>
<h2 data-number="13.1"><span class="header-section-number">13.1</span> Definition <a href="#definition-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">WithDeadline</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">WithTimeout</code></span> are very similar. If we look at the source code of the <span v-highlightjs><code class="go" v-pre style="display: inline">context</code></span> package, we can see that the function <span v-highlightjs><code class="go" v-pre style="display: inline">WithTimeout</code></span> is just a wrapper of <span v-highlightjs><code class="go" v-pre style="display: inline">WithDeadline</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >// source : context.go (in the standard library)
func WithTimeout(parent Context, timeout time.Duration) (Context, CancelFunc) {
    return WithDeadline(parent, time.Now().Add(timeout))
}</code></pre>
<p>If you look at the previous snippet you can see that the timeout duration is added to the current time. Let’s see the signature of the WithDeadline function :</p>
<pre v-highlightjs><code class="go" v-pre >func WithDeadline(parent Context, d time.Time) (Context, CancelFunc)</code></pre>
<p>The function takes two arguments :</p>
<ol type="1">
<li><p>A parent context</p></li>
<li><p>A specific time.</p></li>
</ol>
<div id="usage" class="anchor"></div>
<h2 data-number="13.2"><span class="header-section-number">13.2</span> Usage <a href="#usage"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>As we said in the previous section, deadline and timeout are similar notions. A timeout is expressed as a duration, but a deadline is expressed as a particular point in time (see figure <a href="#fig:Timeout-vs-Deadline" data-reference-type="ref" data-reference="fig:Timeout-vs-Deadline">7</a>)</p>
<figure>
<b-img :src="require('@/assets/images/timeout_deadline.png')" alt="Timeout vs Deadline[fig:Timeout-vs-Deadline]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Timeout vs Deadline<span id="fig:Timeout-vs-Deadline" label="fig:Timeout-vs-Deadline">[fig:Timeout-vs-Deadline]</span></figcaption>
</figure>
<p>WithDeadline can be used where you use WithTimeout. Here is an example of the standard library :</p>
<pre v-highlightjs><code class="go" v-pre >// golang standard library
// src/net/dnsclient_unix.go
// line 133

// exchange sends a query on the connection and hopes for a response.
func (r *Resolver) exchange(ctx context.Context, server string, q dnsmessage.Question, timeout time.Duration) (dnsmessage.Parser, dnsmessage.Header, error) {
    //....
    for _, network := range []string{&quot;udp&quot;, &quot;tcp&quot;} {
        ctx, cancel := context.WithDeadline(ctx, time.Now().Add(timeout))
        defer cancel()

        c, err := r.dial(ctx, network, server)
        if err != nil {
            return dnsmessage.Parser{}, dnsmessage.Header{}, err
        }
        //...
    }
    return dnsmessage.Parser{}, dnsmessage.Header{}, errNoAnswerFromDNSServer
}</code></pre>
<ul>
<li><p>Here the function <span v-highlightjs><code class="go" v-pre style="display: inline">exchange</code></span> takes a context as first parameter.</p></li>
<li><p>For each network (UDP or TCP), it derives the context passed as parameter.</p></li>
<li><p>The input context is derived by calling <span v-highlightjs><code class="go" v-pre style="display: inline">context.WithDeadline</code></span>. The deadline time is created by adding the timeout duration to the current time : <span v-highlightjs><code class="go" v-pre style="display: inline">time.Now().Add(timeout)</code></span></p></li>
<li><p>Note that <strong>immediately</strong> after creating the derived context, there is a deferred call to the cancel function returned by <span v-highlightjs><code class="go" v-pre style="display: inline">context.WithDeadline</code></span>. It means that when the function exchange will return the cancel function will be called.</p></li>
<li><p>For instance, if the dial function returns an error for some reason, the exchange function will return, the cancel function will be called, and the cancellation signal will be propagated to the child context.</p></li>
</ul>
<div id="cancellation-propagation-1" class="anchor"></div>
<h1 data-number="14"><span class="header-section-number">14</span> Cancellation propagation <a href="#cancellation-propagation-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>This section will dive deeper into the mechanism of cancellation propagation. Let’s take an example :</p>
<pre v-highlightjs><code class="go" v-pre >func main(){
    ctx1 := context.Background()
    ctx2, c := context.WithCancel(ctx1)
    defer c()
}</code></pre>
<p>In this small program, we begin by defining a root context : <span v-highlightjs><code class="go" v-pre style="display: inline">ctx1</code></span>. Then we derive this context with a call to <span v-highlightjs><code class="go" v-pre style="display: inline">context.WithCancel</code></span>.</p>
<p>Go will create a new structure. The function that will be called is the following one :</p>
<pre v-highlightjs><code class="go" v-pre >// src/context/context.go

// newCancelCtx returns an initialized cancelCtx.
func newCancelCtx(parent Context) cancelCtx {
    return cancelCtx{Context: parent}
}</code></pre>
<p>A <span v-highlightjs><code class="go" v-pre style="display: inline">cancelCtx</code></span> struct is created, and our root context is embedded inside. Here the type struct <span v-highlightjs><code class="go" v-pre style="display: inline">cancelCtx</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >// src/context/context.go

type cancelCtx struct {
    Context

    mu       sync.Mutex            // protects the following fields
    done     chan struct{}         // created lazily, closed by the first cancel call
    children map[canceler]struct{} // set to nil by the first cancel call
    err      error                 // set to non-nil by the first cancel call
}</code></pre>
<p>We have five fields :</p>
<ul>
<li><p>the <span v-highlightjs><code class="go" v-pre style="display: inline">Context</code></span> (the parent) which is an embedded filed (it has no explicit field name)</p></li>
<li><p>A mutex (named <span v-highlightjs><code class="go" v-pre style="display: inline">mu</code></span>)</p></li>
<li><p>A channel named <span v-highlightjs><code class="go" v-pre style="display: inline">done</code></span></p></li>
<li><p>A field named <span v-highlightjs><code class="go" v-pre style="display: inline">children</code></span> that is a map. Keys are of type <span v-highlightjs><code class="go" v-pre style="display: inline">canceller</code></span> and value of type <span v-highlightjs><code class="go" v-pre style="display: inline">struct{}</code></span></p></li>
<li><p>And an error named <span v-highlightjs><code class="go" v-pre style="display: inline">err</code></span></p></li>
</ul>
<p>Canceller is an interface :</p>
<pre v-highlightjs><code class="go" v-pre >// A canceler is a context type that can be canceled directly. The
// implementations are *cancelCtx and *timerCtx.
type canceler interface {
    cancel(removeFromParent bool, err error)
    Done() &lt;-chan struct{}
}</code></pre>
<p>A type that implements the interface canceller must implement to functions : a cancel function and a <span v-highlightjs><code class="go" v-pre style="display: inline">Done</code></span> function.</p>
<figure>
<b-img :src="require('@/assets/images/withCancel.png')" alt="WithCancel create a derived context and a cancel function[fig:WithCancel-create-a]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">WithCancel create a derived context and a cancel function<span id="fig:WithCancel-create-a" label="fig:WithCancel-create-a">[fig:WithCancel-create-a]</span></figcaption>
</figure>
<p>What happens when we execute the cancel function ? What happens to <strong>ctx2</strong> ?</p>
<ul>
<li><p>The mutex (<span v-highlightjs><code class="go" v-pre style="display: inline">mu</code></span>) will be locked. Hence no other goroutine will be able to modify this context.</p></li>
<li><p>The channel (<span v-highlightjs><code class="go" v-pre style="display: inline">done</code></span>) will be closed</p></li>
<li><p>All children of <span v-highlightjs><code class="go" v-pre style="display: inline">ctx2</code></span> will also be canceled (in this case, we have no children...)</p></li>
<li><p>The mutex will be unlocked.</p></li>
</ul>
<div id="second-derivation" class="anchor"></div>
<h4 data-number="14.0.0.1"><span class="header-section-number">14.0.0.1</span> Second derivation <a href="#second-derivation"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>Let’s extend our example and derive ctx2:</p>
<figure>
<b-img :src="require('@/assets/images/withCancel3Derivatin.png')" alt="Derive a derived context[fig:3-contexts]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Derive a derived context<span id="fig:3-contexts" label="fig:3-contexts">[fig:3-contexts]</span></figcaption>
</figure>
<pre v-highlightjs><code class="go" v-pre >func main() {
    ctx1 := context.Background()
    ctx2, c2 := context.WithCancel(ctx1)
    ctx3, c3 := context.WithCancel(ctx2)
    //...
}</code></pre>
<p>Here we create <span v-highlightjs><code class="go" v-pre style="display: inline">ctx3</code></span><strong>,</strong> a new object of type <span v-highlightjs><code class="go" v-pre style="display: inline">cancelCtx</code></span>. The children context <span v-highlightjs><code class="go" v-pre style="display: inline">ctx3</code></span> will be added to the parent (<span v-highlightjs><code class="go" v-pre style="display: inline">ctx2</code></span>). The parent context <span v-highlightjs><code class="go" v-pre style="display: inline">ctx2</code></span> will keep memory of its children. For the moment, it only has one child <span v-highlightjs><code class="go" v-pre style="display: inline">ctx3</code></span> (see <a href="#fig:3-contexts" data-reference-type="ref" data-reference="fig:3-contexts">9</a>).</p>
<p>Now let’s see what is going on when we call the cancel function <span v-highlightjs><code class="go" v-pre style="display: inline">c2</code></span>.</p>
<ul>
<li><p>The mutex (<span v-highlightjs><code class="go" v-pre style="display: inline">mu</code></span>) will be locked. Hence no other goroutine will be able to modify this context.</p></li>
<li><p>The channel (<span v-highlightjs><code class="go" v-pre style="display: inline">done</code></span>) will be closed</p></li>
<li><p>All children of <span v-highlightjs><code class="go" v-pre style="display: inline">ctx2</code></span> will also be canceled (in this case, we have no children...)</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">ctx3</code></span> will be canceled with the same process</p></li>
<li><p>Here <span v-highlightjs><code class="go" v-pre style="display: inline">ctx1</code></span> (the parent of <span v-highlightjs><code class="go" v-pre style="display: inline">ctx2</code></span>) is an <span v-highlightjs><code class="go" v-pre style="display: inline">emptyCtx</code></span>, hence <span v-highlightjs><code class="go" v-pre style="display: inline">ctx2</code></span> will not be removed from <span v-highlightjs><code class="go" v-pre style="display: inline">ctx1</code></span>.</p></li>
<li><p>The mutex will be unlocked.</p></li>
</ul>
<div id="third-derivation" class="anchor"></div>
<h4 data-number="14.0.0.2"><span class="header-section-number">14.0.0.2</span> Third derivation <a href="#third-derivation"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>Now let’s create another derived context.</p>
<pre v-highlightjs><code class="go" v-pre >func main() {
    ctx1 := context.Background()
    ctx2, c2 := context.WithCancel(ctx1)
    ctx3, c3 := context.WithCancel(ctx2)
    ctx4, c4 := context.WithCancel(ctx3)
}</code></pre>
<figure>
<b-img :src="require('@/assets/images/ctx_4_levels.png')" alt="3 derived contexts[fig:3-derived-contexts]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">3 derived contexts<span id="fig:3-derived-contexts" label="fig:3-derived-contexts">[fig:3-derived-contexts]</span></figcaption>
</figure>
<ul>
<li><p>As you can see in figure <a href="#fig:3-derived-contexts" data-reference-type="ref" data-reference="fig:3-derived-contexts">10</a> we have one root context and three descendants.</p></li>
<li><p>The last one is <span v-highlightjs><code class="go" v-pre style="display: inline">ctx4</code></span>.</p></li>
<li><p>When we call <span v-highlightjs><code class="go" v-pre style="display: inline">c2</code></span> it will cancel <span v-highlightjs><code class="go" v-pre style="display: inline">ctx2</code></span> but also its children (<span v-highlightjs><code class="go" v-pre style="display: inline">ctx3</code></span>).</p></li>
<li><p>When <span v-highlightjs><code class="go" v-pre style="display: inline">ctx3</code></span> will be canceled it will also cancel all its children, and <span v-highlightjs><code class="go" v-pre style="display: inline">ctx4</code></span> will be canceled.</p></li>
</ul>
<figure>
<b-img :src="require('@/assets/images/cancellation_propagation_2.png')" alt="Cancellation propagation[fig:Cancellation-propagation-1]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Cancellation propagation<span id="fig:Cancellation-propagation-1" label="fig:Cancellation-propagation-1">[fig:Cancellation-propagation-1]</span></figcaption>
</figure>
<p>The key information of this section is “when you cancel a context, the cancel operation will be propagated from parent to children”.</p>
<div id="an-important-idiom-defer-cancel" class="anchor"></div>
<h1 data-number="15"><span class="header-section-number">15</span> An important idiom : defer cancel() <a href="#an-important-idiom-defer-cancel"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The following two lines of code are very common :</p>
<pre v-highlightjs><code class="go" v-pre >ctx, cancel = context.WithCancel(ctx)
defer cancel()</code></pre>
<p>You can encounter those lines in the standard library but also in many libraries. As soon as we derive an existing context, the cancel function is called in a defer statement.</p>
<p>As we have seen before, the cancellation instruction propagates from parents to children; why do we need to call cancel explicitly? When building a library, you are not sure that somebody will effectively execute the cancel function in a parent context. By adding the call to cancel in a deferred statement, you ensure that cancel will be called :</p>
<ul>
<li><p>when the function returns (or reach the end of its body)</p></li>
<li><p>or when the goroutine that runs the function panics.</p></li>
</ul>
<div id="goroutine-leak" class="anchor"></div>
<h2 data-number="15.1"><span class="header-section-number">15.1</span> Goroutine leak <a href="#goroutine-leak"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>To understand this phenomenon, we will take an example.</p>
<p>First, we define two functions: <span v-highlightjs><code class="go" v-pre style="display: inline">doSth</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">doSth2</code></span>. Those two functions are dummy. They are taking a context as first parameter. Then they are waiting indefinitely for the channel returned by <span v-highlightjs><code class="go" v-pre style="display: inline">ctx.Done()</code></span> close :</p>
<pre v-highlightjs><code class="go" v-pre >// context/goroutine-leak/main.go 
// ...

func doSth2(ctx context.Context) {
    select {
    case &lt;-ctx.Done():
        log.Println(&quot;second goroutine return&quot;)
        return
    }
}

func doSth(ctx context.Context) {
    select {
    case &lt;-ctx.Done():
        log.Println(&quot;first goroutine return&quot;)
        return
    }
}</code></pre>
<p>We will now use those two functions in a third function called <span v-highlightjs><code class="go" v-pre style="display: inline">launch</code></span>:</p>
<pre v-highlightjs><code class="go" v-pre >// context/goroutine-leak/main.go 
// ...

func launch() {
    ctx := context.Background()
    ctx, _ = context.WithCancel(ctx)
    log.Println(&quot;launch first goroutine&quot;)
    go doSth(ctx)
    log.Println(&quot;launch second goroutine&quot;)
    go doSth2(ctx)
}</code></pre>
<p>In this function, we are first creating a root context (returned by <span v-highlightjs><code class="go" v-pre style="display: inline">context.Background</code></span>). Then we derive this root context. We call the method <span v-highlightjs><code class="go" v-pre style="display: inline">WithCancel()</code></span> to get a context that can be canceled.</p>
<p>Then we launch our two goroutines. Let’s now take a look at our main function :</p>
<pre v-highlightjs><code class="go" v-pre >// context/goroutine-leak/main.go 
// ...

func main() {
    log.Println(&quot;begin program&quot;)
    go launch()
    time.Sleep(time.Millisecond)
    log.Printf(&quot;Gouroutine count: %d\n&quot;, runtime.NumGoroutine())
    for {
    }
}</code></pre>
<p>We start the function <span v-highlightjs><code class="go" v-pre style="display: inline">launch</code></span> in a goroutine. Then we make a small pause (1 millisecond), and we count the number of goroutines. There is a very convenient function that is defined in the runtime package :</p>
<pre v-highlightjs><code class="go" v-pre >runtime.NumGoroutine()</code></pre>
<p>The number of goroutines here should be 3: 1 main goroutine + 1 goroutine that execute doSth + 1 goroutine that execute <span v-highlightjs><code class="go" v-pre style="display: inline">doSth2</code></span>. If we do not call cancel, the two last goroutines will be running indefinitely. Note that we have created another goroutine in the program: the one that starts <span v-highlightjs><code class="go" v-pre style="display: inline">launch</code></span><strong>.</strong> This goroutine will not be counted because it will return almost instantaneously.</p>
<p>When we cancel the context, our two goroutines are returning. Hence the number of goroutines will be reduced to 1 (the main). But here, we do not call the cancel function at all.</p>
<p>Here is the standard output :</p>
<pre v-highlightjs><code class="go" v-pre >2019/05/04 19:01:16 begin program
2019/05/04 19:01:16 launch first goroutine
2019/05/04 19:01:16 launch second goroutine
2019/05/04 19:01:16 Gouroutine count: 3</code></pre>
<p>In the main function, we have no way to cancel our context (because it was defined inside the launch function). We have 2 leaked goroutine! To fix that, we can just modify the function launch and add a deferred statement :</p>
<pre v-highlightjs><code class="go" v-pre >// context/goroutine-leak-fixed/main.go 
// ...

func launch() {
    ctx := context.Background()
    ctx, cancel := context.WithCancel(ctx)
    defer cancel()
    log.Println(&quot;launch first goroutine&quot;)
    go doSth(ctx)
    log.Println(&quot;launch second goroutine&quot;)
    go doSth2(ctx)
}</code></pre>
<p>Now let’s take a look at the logs that are obtained by running this modified version of our program :</p>
<pre v-highlightjs><code class="go" v-pre >2019/05/04 19:15:09 begin program
2019/05/04 19:15:09 launch first goroutine
2019/05/04 19:15:09 launch second goroutine
2019/05/04 19:15:09 first goroutine return
2019/05/04 19:15:09 second goroutine return
2019/05/04 19:15:09 Gouroutine count: 1</code></pre>
<p>Here we have killed our two leaked goroutine!</p>
<div id="withvalue" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="16"><span class="header-section-number">16</span> WithValue <a href="#withvalue"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>A Context can <strong>carry data</strong> with it. This feature is intended to be used with <strong><u>request-scoped</u></strong> data like :</p>
<ul>
<li><p>credentials (a JSON Web Token, for instance)</p></li>
<li><p>request id (to trace the request in the system)</p></li>
<li><p>the IP of the request</p></li>
<li><p>Some headers (ex: the user agent)</p></li>
</ul>
<div id="example" class="anchor"></div>
<h2 data-number="16.1"><span class="header-section-number">16.1</span> Example <a href="#example"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >// context/with-value/main.go 
package main

import (
    &quot;context&quot;
    &quot;fmt&quot;
    &quot;log&quot;
    &quot;net/http&quot;

    uuid &quot;github.com/satori/go.uuid&quot;
)

func main() {
    http.HandleFunc(&quot;/status&quot;, status)
    err := http.ListenAndServe(&quot;:8091&quot;, nil)
    if err != nil {
        log.Fatal(err)
    }
}

type key int

const (
    requestID key = iota
    jwt
)

func status(w http.ResponseWriter, req *http.Request) {
    // Add request id to context
    ctx := context.WithValue(req.Context(), requestID, uuid.NewV4().String())
    // add credentials to context
    ctx = context.WithValue(ctx, jwt, req.Header.Get(&quot;Authorization&quot;))


    upDB, err := isDatabaseUp(ctx)
    if err != nil {
        http.Error(w, &quot;Internal server error&quot;, http.StatusInternalServerError)
        return
    }
    upAuth, err := isMonitoringUp(ctx)
    if err != nil {
        http.Error(w, &quot;Internal server error&quot;, http.StatusInternalServerError)
        return
    }
    fmt.Fprintf(w, &quot;DB up: %t | Monitoring up: %t\n&quot;, upDB, upAuth)
}


func isDatabaseUp(ctx context.Context) (bool, error) {
    // retrieve the request ID value
    reqID, ok := ctx.Value(requestID).(string)
    if !ok {
        return false, fmt.Errorf(&quot;requestID in context does not have the expected type&quot;)
    }
    log.Printf(&quot;req %s - checking db status&quot;, reqID)
    return true, nil
}

func isMonitoringUp(ctx context.Context) (bool, error) {
    // retrieve the request ID value
    reqID, ok := ctx.Value(requestID).(string)
    if !ok {
        return false, fmt.Errorf(&quot;requestID in context does not have the expected type&quot;)
    }
    log.Printf(&quot;req %s - checking monitoring status&quot;, reqID)
    return true, nil
}</code></pre>
<ul>
<li><p>We have created a server that is listening on localhost:8091</p></li>
<li><p>This server has one route : <span v-highlightjs><code class="go" v-pre style="display: inline">"/status"</code></span></p></li>
<li><p>We derive the request context (<span v-highlightjs><code class="go" v-pre style="display: inline">req.Context()</code></span>) with <span v-highlightjs><code class="go" v-pre style="display: inline">ctx := context.WithValue(req.Context(), requestID, uuid.NewV4().String())</code></span></p>
<ul>
<li>We add a key-value pair to the context: the requestID</li>
</ul></li>
<li><p>Then we renew the operation. We add a new key pair to hold the request credentials : <span v-highlightjs><code class="go" v-pre style="display: inline">ctx = context.WithValue(ctx, jwt, req.Header.Get("Authorization"))</code></span></p></li>
</ul>
<p>The context values are then accessible into <span v-highlightjs><code class="go" v-pre style="display: inline">isMonitoringUp</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">isMonitoringUp</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >reqID, ok := ctx.Value(requestID).(string)
if !ok {
    return false, fmt.Errorf(&quot;requestID in context does not have the expected type&quot;)
}</code></pre>
<div id="key-type" class="anchor"></div>
<h2 data-number="16.2"><span class="header-section-number">16.2</span> Key type <a href="#key-type"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Here is the header of the method WithValue :</p>
<pre v-highlightjs><code class="go" v-pre >func WithValue(parent Context, key, val interface{}) Context</code></pre>
<p>The arguments <span v-highlightjs><code class="go" v-pre style="display: inline">key</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">val</code></span> are of type <span v-highlightjs><code class="go" v-pre style="display: inline">interface{}</code></span>. In other words, they can have any type. Only one restriction should be respected, the type of <span v-highlightjs><code class="go" v-pre style="display: inline">key</code></span> should be <strong>comparable</strong>.</p>
<ul>
<li><p>We can share a context across several packages.</p></li>
<li><p>You might want to restrict the access to context values outside the package where values were added.</p></li>
<li><p>To do that, you can create an unexported type</p></li>
<li><p>All keys will be of this type.</p></li>
<li><p>We will define keys globally inside the package :</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >type key int

const (
    requestID key = iota
    jwt
)</code></pre>
<p>In the previous example, we created a type key with underlying type int (comparable). Then we defined two unexported global constants. Those constants are then used to add a value and to retrieve a value from the context :</p>
<pre v-highlightjs><code class="go" v-pre >// add a value
ctx := context.WithValue(req.Context(), requestID, uuid.NewV4().String())

// get a value
reqID, ok := ctx.Value(requestID).(string)</code></pre>
<div id="missing-value-type-expected-different-than-the-actual-type" class="anchor"></div>
<h4 data-number="16.2.0.1"><span class="header-section-number">16.2.0.1</span> Missing value &amp; type expected different than the actual type <a href="#missing-value-type-expected-different-than-the-actual-type"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<ul>
<li><p>When the key-value pair is not found in the context, <span v-highlightjs><code class="go" v-pre style="display: inline">ctx.Value</code></span> will return <span v-highlightjs><code class="go" v-pre style="display: inline">nil</code></span>.</p></li>
<li><p>That’s why we are making a <strong>type assertion</strong>: to protect us against missing values or values that have not the required type.</p></li>
</ul>
<div id="questions" class="anchor"></div>
<h2 data-number="16.3"><span class="header-section-number">16.3</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>What is the difference between a deadline and a timeout ?</p></li>
<li><p>Fill in the blanks. Each node of a linked list contains a ____ value and an ____.</p></li>
<li><p>How to create an empty context?</p></li>
<li><p>With which method(s) can you derive a context?</p></li>
<li><p>When the key-value pair is not found in the context, what is returned by<span v-highlightjs><code class="go" v-pre style="display: inline">ctx.Value(key)</code></span> ?</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="16.4"><span class="header-section-number">16.4</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>What is the difference between a deadline and a timeout?</p>
<ol type="1">
<li><p>Deadline: a precise point in time. ex: 12 December 2027</p></li>
<li><p>Timeout: a duration. ex: 12 seconds</p></li>
</ol></li>
<li><p>Fill in the blanks. Each node of a linked list contains a ____ value and an ____.</p>
<ol type="1">
<li><p>Each node of a linked list contains a <u>data</u> value and an the <u>address in memory of the next element</u></p></li>
<li><p>Except for the final node. It has no address set.</p></li>
</ol></li>
<li><p>How to create an empty context?</p>
<ol type="1">
<li>ctx := context.Background()</li>
</ol></li>
<li><p>With which method(s) can you derive a context?</p>
<ol type="1">
<li><p>WithCancel</p></li>
<li><p>WithTimeout</p></li>
<li><p>WithDeadline</p></li>
<li><p>WithValue</p></li>
</ol></li>
<li><p>When the key-value pair is not found in the context, what is returned by<span v-highlightjs><code class="go" v-pre style="display: inline">ctx.Value(key)</code></span></p>
<ol type="1">
<li>nil</li>
</ol></li>
</ol>
<div id="key-takeaways" class="anchor"></div>
<h1 data-number="17"><span class="header-section-number">17</span> Key Takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Context is a package from the standard library</p></li>
<li><p>We can use context to :</p>
<ul>
<li><p>Cancellation propagation (ex: cancel all the work chain if the API consumer has dropped the connection)</p></li>
<li><p>Transmission of request-scoped data along with the call stack.</p></li>
<li><p>Set deadlines and timeouts.</p></li>
</ul></li>
<li><p>Deadline = a precise point in time</p></li>
<li><p>Timeout = a duration</p></li>
<li><p>Internally the package context is built with a linked list.</p></li>
<li><p>A linked list is a collection of data. Each node of a linked list contains a data value and an address in memory of the next element.</p></li>
<li><p>To create an empty context, use :</p>
<pre v-highlightjs><code class="go" v-pre >ctx := context.Background()</code></pre></li>
<li><p>We can derive each context with the following methods :</p>
<ul>
<li><p>WithCancel : <span v-highlightjs><code class="go" v-pre style="display: inline">ctx, cancel := context.WithCancel(context.Background())</code></span></p></li>
<li><p>WithTimeout : <span v-highlightjs><code class="go" v-pre style="display: inline">ctx, cancel := context.WithTimeout(context.Background(), 3*time.Second)</code></span></p></li>
<li><p>WithDeadline : <span v-highlightjs><code class="go" v-pre style="display: inline">ctx, cancel := context.WithDeadline(context.Background(), deadline)</code></span></p></li>
<li><p>WithValue : <span v-highlightjs><code class="go" v-pre style="display: inline">ctx := context.WithValue(context.Background(),"key","value")</code></span></p></li>
</ul></li>
<li><p>By deriving a context, you create a node in the linked list of contexts.</p></li>
<li><p>When you cancel a context, the cancel operation will be propagated from parent to children contexts.</p></li>
<li><p>Context can carry request-scoped values.</p></li>
<li><p>To <strong>add</strong> a value, derive the context like this : <span v-highlightjs><code class="go" v-pre style="display: inline">ctx := context.WithValue(req.Context(), requestID, uuid.NewV4().String())</code></span></p></li>
<li><p>To <strong>retrieve</strong> a value from context use this syntax : <span v-highlightjs><code class="go" v-pre style="display: inline">reqID, ok := ctx.Value(requestID).(string)</code></span></p></li>
<li><p>When no values are retrieved with the given key <span v-highlightjs><code class="go" v-pre style="display: inline">ctx.Value</code></span> will return <span v-highlightjs><code class="go" v-pre style="display: inline">nil</code></span></p></li>
<li><p>Usually, a global unexported variable/constant of an unexported type is used as key.</p></li>
</ul>
<section class="footnotes" role="doc-endnotes">
<hr />
<ol>
<li id="fn1" role="doc-endnote"><p>https://github.com/golang/go/wiki/SubRepositories<a href="#fnref1" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
</ol>
</section>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap37Context"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap36ProgramProfiling'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Program Profiling</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap38Generics'}">
									<p><u><small>Next</small></u></p>
									<p><small>Generics</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Context - Practical Go Lessons"
const description = "This chapter is dedicated to the context package in Go. In the first part of this chapter, we will discover what is a “context” and what its purposes are. In the second part, we will see how we can use the context package in real-life programs."

export default {
  name: "Chap37Context",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
